// import {Buffer} from "buffer";
// // import {crc16xmodem as crc16} from "crc";
// const PACKET_SIZE_128  = 128;
// const PACKET_SIZE_1024 = 1024;
// const SOH         = 0x01; // 128 byte blocks
// const STX         = 0x02; // 1K blocks 1024字节数据包
// const EOT         = 0x04; // 结束传输
// const ACK         = 0x06; // 回应
// const NAK         = 0x15; // 不回应
// const CA          = 0x18; // 24 传输中止
// const CRC16       = 0x43; // 67 "C"
// // const ABORT1 = 0x41 // 65
// // const ABORT2 = 0x61 // 97

// /*YModem的采用的是CRC16-CCITT欧洲版本的CRC校验，它的生成多项式为：x16+x12+x5+1*/


// var eot_pack    =  Buffer.alloc(PACKET_SIZE_128 + 5, 0x00);eot_pack[0] = SOH;eot_pack[2] = 0xff;
//     /* crc == 0*/

// function crc16xmodem(packet ,begin,len, previous){
//     let stop_at = begin + len;
//     let crc = typeof previous !== 'undefined' ? ~~previous : 0x0;
//     for (; begin < stop_at; begin++) {
//         let code = (crc >>> 8) & 0xff;
//         code ^= packet[begin] & 0xff;
//         code ^= code >>> 4;
//         crc = (crc << 8) & 0xffff;
//         crc ^= code;
//         code = (code << 5) & 0xffff;
//         crc ^= code;
//         code = (code << 7) & 0xffff;
//         crc ^= code;
//     }
//     return crc;
// }


// function makeFileHeader(filename, filesize) {
//     let File_HD_SIZE = 128
//     var payload = Buffer.alloc(File_HD_SIZE+3+2, 0x00);
//     payload[0] = SOH;
//     payload[1] = 0;
//     payload[2] = 0xff;
//     var offset = 3;
//     if (filename) {
//         payload.write(filename, offset);
//         offset += filename.length + 1;
//     }
//     if (filesize) {
//         payload.write(filesize.toString() + " ", offset);
//     }
//     var crc = crc16xmodem(payload,3,File_HD_SIZE);
//     payload.writeUInt16BE(crc, payload.byteLength - 2);
//     return payload;
// }

// function splitFile(buffer) {
//     let totalBytes= buffer.byteLength;
//     let maxPack = parseInt((buffer.byteLength + PACKET_SIZE_1024 - 1)/PACKET_SIZE_1024);
//     var array = [];
//     for(let i = 0;i< maxPack;i++){
//         let is_last  = (i+1) == maxPack?true:false;
//         let packSize = PACKET_SIZE_1024;
//         if(is_last &&  totalBytes - i*PACKET_SIZE_1024 <= 128){
//             packSize = PACKET_SIZE_128;
//         }
//         var chunk = Buffer.alloc(packSize +3+2,is_last ? 0x1A:0x00);

//         chunk[0] = (packSize == PACKET_SIZE_1024) ?STX:SOH;
//         chunk[1] = (i+1)&0xff;
//         chunk[2] = 0xff - chunk[1];

//         buffer.copy(chunk, 0+3, PACKET_SIZE_1024*i, PACKET_SIZE_1024*i + packSize);
//         var crc = crc16xmodem(chunk,3,packSize);
//         chunk.writeUInt16BE(crc, chunk.byteLength - 2);
//         array.push(chunk);
//     }
//     // eslint-disable-next-line
//     return array;
// }

// function splitBuffer(buffer, size){
//     let totalBytes= buffer.byteLength;
//     let maxPack = parseInt((buffer.byteLength + size - 1)/size);
//     var array = [];
//     for(let i = 0;i< maxPack;i++){
//         let is_last  = (i+1) == maxPack?true:false;
//         let packSize = size;
//         if(is_last ){
//             packSize = totalBytes % size;
//         }
//         var chunk = Buffer.alloc(packSize,0x00);
//         buffer.copy(chunk, 0, size*i, size*i + packSize);
//         array.push(chunk);
//     }
//     // eslint-disable-next-line
//     // debugger
//     return array;
// }


// export function transfer(serial, filename, buffer,logger=console.log) {
//     // eslint-disable-next-line
//     return new Promise((resolve, reject) => {
//         var file_trunks  = [];
//         var totalBytes   = 0;
//         var writtenBytes = 0;
//         var seq          = 0;
//         var session      = false;
//         var sending      = false;
//         var finished     = false;

//         // convert Uint8Array to Buffer
//         buffer = Buffer.from(buffer.buffer);

//         async function sendBuffer(buffer,once_len=0) {
//             if(!once_len){
//                 return await serial.write(buffer, "binary");
//             }
//             async function bulk() {
//                 var chunks = splitBuffer(buffer, once_len);
//                 for (const chunk of chunks) {
//                     var arr = new Uint8Array(chunk.buffer);
//                     await serial.write(arr, "binary");
//                 }
//             }
//             return await bulk();
//         }

//         /* Send packet */
//         async function sendPacket() {
//             logger(`sendPacket seq:${seq}/${file_trunks.length}  \r`);
//             if (seq < file_trunks.length) {
//                 var packet = file_trunks[seq];
//                 await sendBuffer(packet);
//             } else {
//                 if (sending) {
//                     await sendBuffer(Buffer.from([EOT]));
//                 }
//             }
//         }
//         /* Handler for data from Ymodem */
//         function handler(data) {
//             let PreChar =0;
//             for (var i = 0; i < data.byteLength; i++) {
//                 if (!finished) {
//                     var ch = data[i];
//                     if (ch === CRC16) {
//                         logger(`RCV: C @${seq}`);
//                         if (seq >= file_trunks.length){
//                             logger(`SEND EOT @${seq}`);
//                             sendBuffer(eot_pack);
//                         }
//                         else if (PreChar != CRC16){
//                             sendPacket();
//                             sending = true;
//                         }
//                     } else if (ch === ACK) {
//                         logger(`RCV: ACK @${seq}`);
//                         if (!session) {
//                             close();
//                         }
//                         if (sending) {
//                             if(seq == 0){//HEADER ACK ;DATA PACK followed by next C
//                                 seq++;
//                             }
//                             else if (seq < file_trunks.length) {
//                                 if (writtenBytes < totalBytes) {
//                                     writtenBytes = (seq + 1) * PACKET_SIZE_1024;
//                                     if (writtenBytes > totalBytes) {
//                                         writtenBytes = totalBytes;
//                                     }
//                                 }
//                                 seq++;
//                                 sendPacket();
//                             } else {
//                                 /* send complete */
//                                 sending = false;
//                                 session = false;
//                                 // send null header for end of session
//                                 logger(`SEND EOT @${seq}`);
//                                 sendBuffer(eot_pack);
//                             }
//                         }
//                     } else if (ch === NAK) {
//                         sendPacket();
//                     } else if (ch === CA) {
//                         logger(`RCV: CA @${seq}`);
//                         close("CA");
//                     }
//                     PreChar = ch;
//                 }
//             }
//         }

//         /* Finish transmittion */
//         function close(ch='') {
//             session = false;
//             sending = false;
//             serial.removeListener("data", handler);
//             logger(`CLOSE BY [${ch}]`);
//             if (!finished) {
//                 const result = {
//                     filePath: filename,
//                     totalBytes: totalBytes,
//                     writtenBytes: writtenBytes,
//                 };
//                 resolve(result);
//             }
//             finished = true;
//         }

//         // Make file header payload
//         totalBytes = buffer.byteLength;
//         var headerPayload = makeFileHeader(filename, totalBytes);
//         file_trunks.push(headerPayload);

//         // Make file data packets
//         var payloads = splitFile(buffer);
//         payloads.forEach((payload) => {
//             file_trunks.push(payload);
//         });
//         // Start to transfer
//         // eslint-disable-next-line
//         session = true;
//         serial.on("data", handler);
//     });
// }



//以下是调试文件
// import { Buffer } from "buffer";

// const PACKET_SIZE_128 = 128;
// const PACKET_SIZE_1024 = 1024;
// const SOH = 0x01; // 128 byte blocks
// const STX = 0x02; // 1K blocks 1024字节数据包
// const EOT = 0x04; // 结束传输
// const ACK = 0x06; // 回应
// const NAK = 0x15; // 不回应
// const CA = 0x18; // 24 传输中止
// const CRC16 = 0x43; // 67 "C"

// var eot_pack = Buffer.alloc(PACKET_SIZE_128 + 5, 0x00);
// eot_pack[0] = SOH;
// eot_pack[2] = 0xff;

// function crc16xmodem(packet, begin, len, previous) {
//     let stop_at = begin + len;
//     let crc = typeof previous !== 'undefined' ? ~~previous : 0x0;
//     for (; begin < stop_at; begin++) {
//         let code = (crc >>> 8) & 0xff;
//         code ^= packet[begin] & 0xff;
//         code ^= code >>> 4;
//         crc = (crc << 8) & 0xffff;
//         crc ^= code;
//         code = (code << 5) & 0xffff;
//         crc ^= code;
//         code = (code << 7) & 0xffff;
//         crc ^= code;
//     }
//     return crc;
// }

// function makeFileHeader(filename, filesize) {
//     let File_HD_SIZE = 128;
//     var payload = Buffer.alloc(File_HD_SIZE + 3 + 2, 0x00);
//     payload[0] = SOH;
//     payload[1] = 0;
//     payload[2] = 0xff;
//     var offset = 3;
//     if (filename) {
//         payload.write(filename, offset);
//         offset += filename.length + 1;
//     }
//     if (filesize) {
//         payload.write(filesize.toString() + " ", offset);
//     }
//     var crc = crc16xmodem(payload, 3, File_HD_SIZE);
//     payload.writeUInt16BE(crc, payload.byteLength - 2);
//     return payload;
// }

// function splitFile(buffer) {
//     let totalBytes = buffer.byteLength;
//     let maxPack = parseInt((buffer.byteLength + PACKET_SIZE_1024 - 1) / PACKET_SIZE_1024);
//     var array = [];
//     for (let i = 0; i < maxPack; i++) {
//         let is_last = (i + 1) == maxPack ? true : false;
//         let packSize = PACKET_SIZE_1024;
//         if (is_last && totalBytes - i * PACKET_SIZE_1024 <= 128) {
//             packSize = PACKET_SIZE_128;
//         }
//         var chunk = Buffer.alloc(packSize + 3 + 2, is_last ? 0x1A : 0x00);

//         chunk[0] = (packSize == PACKET_SIZE_1024) ? STX : SOH;
//         chunk[1] = (i + 1) & 0xff;
//         chunk[2] = 0xff - chunk[1];

//         buffer.copy(chunk, 0 + 3, PACKET_SIZE_1024 * i, PACKET_SIZE_1024 * i + packSize);
//         var crc = crc16xmodem(chunk, 3, packSize);
//         chunk.writeUInt16BE(crc, chunk.byteLength - 2);
//         array.push(chunk);
//     }
//     return array;
// }

// function splitBuffer(buffer, size) {
//     let totalBytes = buffer.byteLength;
//     let maxPack = parseInt((buffer.byteLength + size - 1) / size);
//     var array = [];
//     for (let i = 0; i < maxPack; i++) {
//         let is_last = (i + 1) == maxPack ? true : false;
//         let packSize = size;
//         if (is_last) {
//             packSize = totalBytes % size;
//         }
//         var chunk = Buffer.alloc(packSize, 0x00);
//         buffer.copy(chunk, 0, size * i, size * i + packSize);
//         array.push(chunk);
//     }
//     return array;
// }

// export function transfer(serial, filename, buffer, logger = console.log) {
//     return new Promise((resolve) => {
//         var file_trunks = [];
//         var totalBytes = 0;
//         var writtenBytes = 0;
//         var seq = 0;
//         var session = false;
//         var sending = false;
//         var finished = false;

//         buffer = Buffer.from(buffer.buffer);

//         async function sendBuffer(buffer, once_len = 0) {
//             if (!once_len) {
//                 logger(`sendBuffer: Writing buffer of length ${buffer.length}`);
//                 return await serial.write(buffer, "binary");
//             }
//             async function bulk() {
//                 var chunks = splitBuffer(buffer, once_len);
//                 for (const chunk of chunks) {
//                     var arr = new Uint8Array(chunk.buffer);
//                     await serial.write(arr, "binary");
//                     logger(`sendBuffer: Writing chunk of length ${chunk.length}`);
//                 }
//             }
//             return await bulk();
//         }

//         async function sendPacket() {
//             logger(`sendPacket seq:${seq}/${file_trunks.length}`);
//             if (seq < file_trunks.length) {
//                 var packet = file_trunks[seq];
//                 await sendBuffer(packet);
//             } else {
//                 if (sending) {
//                     await sendBuffer(Buffer.from([EOT]));
//                     logger(`sendPacket: Sent EOT`);
//                 }
//             }
//         }

//         function handler(data) {
//             let PreChar = 0;
//             for (var i = 0; i < data.byteLength; i++) {
//                 if (!finished) {
//                     var ch = data[i];
//                     logger(`RCV: ${String.fromCharCode(ch)} (${ch}) @${seq}`);
//                     if (ch === CRC16) {
//                         logger(`RCV: C @${seq}`);
//                         if (seq >= file_trunks.length) {
//                             logger(`SEND EOT @${seq}`);
//                             sendBuffer(eot_pack);
//                         } else if (PreChar != CRC16) {
//                             sendPacket();
//                             sending = true;
//                         }
//                     } else if (ch === ACK) {
//                         logger(`RCV: ACK @${seq}`);
//                         if (!session) {
//                             close();
//                         }
//                         if (sending) {
//                             if (seq == 0) { // HEADER ACK; DATA PACK followed by next C
//                                 seq++;
//                             } else if (seq < file_trunks.length) {
//                                 if (writtenBytes < totalBytes) {
//                                     writtenBytes = (seq + 1) * PACKET_SIZE_1024;
//                                     if (writtenBytes > totalBytes) {
//                                         writtenBytes = totalBytes;
//                                     }
//                                 }
//                                 seq++;
//                                 sendPacket();
//                             } else {
//                                 sending = false;
//                                 session = false;
//                                 logger(`SEND EOT @${seq}`);
//                                 sendBuffer(eot_pack);
//                             }
//                         }
//                     } else if (ch === NAK) {
//                         logger(`RCV: NAK @${seq}`);
//                         sendPacket();
//                     } else if (ch === CA) {
//                         logger(`RCV: CA @${seq}`);
//                         close("CA");
//                     }
//                     PreChar = ch;
//                 }
//             }
//         }

//         function close(ch = '') {
//             session = false;
//             sending = false;
//             serial.removeListener("data", handler);
//             logger(`CLOSE BY [${ch}]`);
//             if (!finished) {
//                 const result = {
//                     filePath: filename,
//                     totalBytes: totalBytes,
//                     writtenBytes: writtenBytes,
//                 };
//                 resolve(result);
//             }
//             finished = true;
//         }

//         totalBytes = buffer.byteLength;
//         var headerPayload = makeFileHeader(filename, totalBytes);
//         file_trunks.push(headerPayload);
//         logger("File header created");

//         var payloads = splitFile(buffer);
//         payloads.forEach((payload) => {
//             file_trunks.push(payload);
//         });
//         logger("File data packets created");

//         session = true;
//         serial.on("data", handler);
//         logger("YModem transfer session started");
//     });
// }



//调试2

// src/utils/ymodem.js
// src/Modules/ymodem.js

// src/Modules/ymodem.js

// import { Buffer } from "buffer";

// const PACKET_SIZE_128 = 128;
// const PACKET_SIZE_1024 = 1024;
// const SOH = 0x01;
// const STX = 0x02;
// const EOT = 0x04;
// const ACK = 0x06;
// const NAK = 0x15;
// const CA = 0x18;
// const CRC16 = 0x43;

// var eot_pack = Buffer.alloc(PACKET_SIZE_128 + 5, 0x00);
// eot_pack[0] = SOH;
// eot_pack[2] = 0xff;

// function crc16xmodem(packet, begin, len, previous) {
//     let stop_at = begin + len;
//     let crc = typeof previous !== 'undefined' ? ~~previous : 0x0;
//     for (; begin < stop_at; begin++) {
//         let code = (crc >>> 8) & 0xff;
//         code ^= packet[begin] & 0xff;
//         code ^= code >>> 4;
//         crc = (crc << 8) & 0xffff;
//         crc ^= code;
//         code = (code << 5) & 0xffff;
//         crc ^= code;
//         code = (code << 7) & 0xffff;
//         crc ^= code;
//     }
//     return crc;
// }
//验证文件完整性
// function makeFileHeader(filename, filesize) {
//     let File_HD_SIZE = 128;
//     var payload = Buffer.alloc(File_HD_SIZE + 3 + 2, 0x00);
//     payload[0] = SOH;
//     payload[1] = 0;
//     payload[2] = 0xff;
//     var offset = 3;
//     if (filename) {
//         payload.write(filename, offset);
//         offset += filename.length + 1;
//     }
//     if (filesize) {
//         payload.write(filesize.toString() + " ", offset);
//     }
//     var crc = crc16xmodem(payload, 3, File_HD_SIZE);
//     payload.writeUInt16BE(crc, payload.byteLength - 2);
//     return payload;
// }

// function splitFile(buffer) {
//     let totalBytes = buffer.byteLength;
//     let maxPack = Math.ceil(buffer.byteLength / PACKET_SIZE_1024);
//     var array = [];
//     for (let i = 0; i < maxPack; i++) {
//         let is_last = (i + 1) == maxPack ? true : false;
//         let packSize = PACKET_SIZE_1024;
//         if (is_last && totalBytes - i * PACKET_SIZE_1024 <= 128) {
//             packSize = PACKET_SIZE_128;
//         }
//         var chunk = Buffer.alloc(packSize + 3 + 2, is_last ? 0x1A : 0x00);

//         chunk[0] = (packSize == PACKET_SIZE_1024) ? STX : SOH;
//         chunk[1] = (i + 1) & 0xff;
//         chunk[2] = 0xff - chunk[1];

//         buffer.copy(chunk, 3, PACKET_SIZE_1024 * i, PACKET_SIZE_1024 * i + packSize);
//         var crc = crc16xmodem(chunk, 3, packSize);
//         chunk.writeUInt16BE(crc, chunk.byteLength - 2);
//         array.push(chunk);
//     }
//     return array;
// }

// function splitFile(buffer) {
//     let totalBytes = buffer.byteLength;
//     let maxPack = parseInt((buffer.byteLength + PACKET_SIZE_1024 - 1) / PACKET_SIZE_1024);
//     var array = [];
//     for (let i = 0; i < maxPack; i++) {
//         let is_last = (i + 1) == maxPack ? true : false;
//         let packSize = PACKET_SIZE_1024;
//         if (is_last && totalBytes - i * PACKET_SIZE_1024 <= 128) {
//             packSize = PACKET_SIZE_128;
//         }
//         var chunk = Buffer.alloc(packSize + 3 + 2, is_last ? 0x1A : 0x00);

//         chunk[0] = (packSize == PACKET_SIZE_1024) ? STX : SOH;
//         chunk[1] = (i + 1) & 0xff;
//         chunk[2] = 0xff - chunk[1];

//         buffer.copy(chunk, 0 + 3, PACKET_SIZE_1024 * i, PACKET_SIZE_1024 * i + packSize);
//         var crc = crc16xmodem(chunk, 3, packSize);
//         chunk.writeUInt16BE(crc, chunk.byteLength - 2);
//         array.push(chunk);
//     }
//     return array;
// }

// function splitBuffer(buffer, size) {
//     let totalBytes = buffer.byteLength;
//     let maxPack = Math.ceil(buffer.byteLength / size);
//     var array = [];
//     for (let i = 0; i < maxPack; i++) {
//         let is_last = (i + 1) == maxPack ? true : false;
//         let packSize = size;
//         if (is_last) {
//             packSize = totalBytes % size;
//         }
//         var chunk = Buffer.alloc(packSize, 0x00);
//         buffer.copy(chunk, 0, size * i, size * i + packSize);
//         array.push(chunk);
//     }
//     return array;
// }

// function splitBuffer(buffer, size) {
//     let totalBytes = buffer.byteLength;
//     let maxPack = parseInt((buffer.byteLength + size - 1) / size);
//     var array = [];
//     for (let i = 0; i < maxPack; i++) {
//         let is_last = (i + 1) == maxPack ? true : false;
//         let packSize = size;
//         if (is_last) {
//             packSize = totalBytes % size;
//         }
//         var chunk = Buffer.alloc(packSize, 0x00);
//         buffer.copy(chunk, 0, size * i, size * i + packSize);
//         array.push(chunk);
//     }
//     return array;
// }


// export function transfer(serial, filename, buffer, logger = console.log) {
//     console.log("transfer" , serial);
//     return new Promise((resolve) => {
//         var file_trunks = [];
//         var totalBytes = 0;
//         var writtenBytes = 0;
//         var seq = 0;
//         var session = false;
//         var sending = false;
//         var finished = false;

//         buffer = Buffer.from(buffer.buffer);

//         async function sendBuffer(buffer, once_len = 0) {
//             if (!once_len) {
//                 logger(`sendBuffer: Writing buffer of length ${buffer.length}`);
//                 return await serial.write(buffer, "binary");
//             }
//             async function bulk() {
//                 var chunks = splitBuffer(buffer, once_len);
//                 for (const chunk of chunks) {
//                     var arr = new Uint8Array(chunk.buffer);
//                     await serial.write(arr, "binary");
//                     logger(`sendBuffer: Writing chunk of length ${chunk.length}`);
//                 }
//             }
//             return await bulk();
//         }

//         async function sendPacket() {
//             logger(`sendPacket seq:${seq}/${file_trunks.length}`);
//             if (seq < file_trunks.length) {
//                 var packet = file_trunks[seq];
//                 await sendBuffer(packet);
//             } else {
//                 if (sending) {
//                     await sendBuffer(Buffer.from([EOT]));
//                     logger(`sendPacket: Sent EOT`);
//                 }
//             }
//         }

//         function handler(data) {
//             let PreChar = 0;
//             for (var i = 0; i < data.byteLength; i++) {
//                 if (!finished) {
//                     var ch = data[i];
//                     logger(`RCV: ${String.fromCharCode(ch)} (${ch}) @${seq}`);
//                     if (ch === CRC16) {
//                         logger(`RCV: C @${seq}`);
//                         if (seq >= file_trunks.length) {
//                             logger(`SEND EOT @${seq}`);
//                             sendBuffer(eot_pack);
//                         } else if (PreChar != CRC16) {
//                             sendPacket();
//                             sending = true;
//                         }
//                     } else if (ch === ACK) {
//                         logger(`RCV: ACK @${seq}`);
//                         if (!session) {
//                             close();
//                         }
//                         if (sending) {
//                             if (seq == 0) { // HEADER ACK; DATA PACK followed by next C
//                                 seq++;
//                             } else if (seq < file_trunks.length) {
//                                 if (writtenBytes < totalBytes) {
//                                     writtenBytes = (seq + 1) * PACKET_SIZE_1024;
//                                     if (writtenBytes > totalBytes) {
//                                         writtenBytes = totalBytes;
//                                     }
//                                 }
//                                 seq++;
//                                 sendPacket();
//                             } else {
//                                 sending = false;
//                                 session = false;
//                                 logger(`SEND EOT @${seq}`);
//                                 sendBuffer(eot_pack);
//                             }
//                         }
//                     } else if (ch === NAK) {
//                         logger(`RCV: NAK @${seq}`);
//                         sendPacket();
//                     } else if (ch === CA) {
//                         logger(`RCV: CA @${seq}`);
//                         close("CA");
//                     }
//                     PreChar = ch;
//                 }
//             }
//         }

//         function close(ch = '') {
//             session = false;
//             sending = false;
//             serial.removeListener("data", handler);
//             logger(`CLOSE BY [${ch}]`);
//             if (!finished) {
//                 const result = {
//                     filePath: filename,
//                     totalBytes: totalBytes,
//                     writtenBytes: writtenBytes,
//                 };
//                 resolve(result);
//             }
//             finished = true;
//         }

//         totalBytes = buffer.byteLength;
//         var headerPayload = makeFileHeader(filename, totalBytes);
//         file_trunks.push(headerPayload);
//         logger("File header created");

//         var payloads = splitFile(buffer);
//         payloads.forEach((payload) => {
//             file_trunks.push(payload);
//         });
//         logger("File data packets created");

//         session = true;
//         serial.on("data", handler);
//         logger("YModem transfer session started");
//     });
// }



// export function transfer(serial, filename, buffer, logger = console.log, progressCallback = null) {
//     if (!serial) {
//         throw new Error("Serial object is null or undefined.");
//     }

//     return new Promise((resolve, reject) => {
//         var file_trunks = [];
//         var totalBytes = 0;
//         var writtenBytes = 0;
//         var seq = 0;
//         var session = false;
//         var sending = false;
//         var finished = false;

//         buffer = Buffer.from(buffer.buffer);

//         if (serial.readable.locked) {
//             serial.readable.getReader().releaseLock();
//         }
//         if (serial.writable.locked) {
//             serial.writable.getWriter().releaseLock();
//         }

//         let writer, reader;

//         try {
//             writer = serial.writable.getWriter();
//             reader = serial.readable.getReader();
//         } catch (error) {
//             reject(`Error obtaining reader or writer: ${error.message}`);
//             return;
//         }

//         async function sendBuffer(buffer, once_len = 0) {
//             if (!once_len) {
//                 await writer.write(buffer);
//                 return;
//             }
//             var chunks = splitBuffer(buffer, once_len);
//             for (const chunk of chunks) {
//                 await writer.write(chunk);
//             }
//         }

//         async function sendPacket() {
//             logger(`sendPacket seq:${seq}/${file_trunks.length}`);
//             if (seq < file_trunks.length) {
//                 var packet = file_trunks[seq];
//                 await sendBuffer(packet);
//                 writtenBytes += packet.length;
//                 // 确保writtenBytes不会超过totalBytes
//                 if (writtenBytes > totalBytes) {
//                     writtenBytes = totalBytes;
//                 }
//                 // 调用进度回调函数
//                 if (progressCallback) {
//                     const progress = Math.floor((writtenBytes / totalBytes) * 100);
//                     progressCallback(progress);
//                 }
//             } else {
//                 if (sending) {
//                     await sendBuffer(Buffer.from([EOT]));
//                 }
//             }
//         }

//         function handler(data) {
//             let PreChar = 0;
//             for (var i = 0; i < data.byteLength; i++) {
//                 if (!finished) {
//                     var ch = data[i];
//                     if (ch === CRC16) {
//                         logger(`RCV: C @${seq}`);
//                         if (seq >= file_trunks.length) {
//                             logger(`SEND EOT @${seq}`);
//                             sendBuffer(eot_pack);
//                         } else if (PreChar != CRC16) {
//                             sendPacket();
//                             sending = true;
//                         }
//                     } else if (ch === ACK) {
//                         logger(`RCV: ACK @${seq}`);
//                         if (!session) {
//                             close();
//                         }
//                         if (sending) {
//                             if (seq === 0) {
//                                 seq++;
//                             } else if (seq < file_trunks.length) {
//                                 if (writtenBytes < totalBytes) {
//                                     writtenBytes = (seq + 1) * PACKET_SIZE_1024;
//                                     if (writtenBytes > totalBytes) {
//                                         writtenBytes = totalBytes;
//                                     }
//                                 }
//                                 seq++;
//                                 sendPacket();
//                             } else {
//                                 sending = false;
//                                 session = false;
//                                 logger(`SEND EOT @${seq}`);
//                                 sendBuffer(eot_pack);
//                             }
//                         }
//                     } else if (ch === NAK) {
//                         sendPacket();
//                     } else if (ch === CA) {
//                         logger(`RCV: CA @${seq}`);
//                         close("CA");
//                     }
//                     PreChar = ch;
//                 }
//             }
//         }

//         async function close(ch = '') {
//             session = false;
//             sending = false;
//             finished = true; // 标记完成状态，停止 readLoop

//             logger(`CLOSE BY [${ch}]`);
//             try {
//                 await reader.cancel();
//                 reader.releaseLock();
//                 writer.releaseLock();
//             } catch (error) {
//                 logger(`Error releasing locks: ${error.message}`);
//             }

//             // 验证传输的完整性
//             if (writtenBytes !== totalBytes) {
//                 reject(new Error(`File transfer incomplete: written ${writtenBytes} bytes, expected ${totalBytes} bytes.`));
//             } else {
//                 const result = {
//                     filePath: filename,
//                     totalBytes: totalBytes,
//                     writtenBytes: writtenBytes,
//                 };
//                 resolve(result);
//             }
//         }

//         totalBytes = buffer.byteLength;
//         var headerPayload = makeFileHeader(filename, totalBytes);
//         file_trunks.push(headerPayload);

//         var payloads = splitFile(buffer);
//         payloads.forEach((payload) => {
//             file_trunks.push(payload);
//         });

//         session = true;

//         async function readLoop() {
//             while (!finished) {
//                 try {
//                     const { value, done } = await reader.read();
//                     if (done || finished) {
//                         break;
//                     }
//                     if (value) {
//                         handler(value);
//                     }
//                 } catch (error) {
//                     if (!finished) {
//                         logger("readLoop error:", error);
//                         reject(error);
//                         break;
//                     }
//                 }
//             }
//         }

//         readLoop().catch((error) => {
//             logger("readLoop error:", error);
//             reject(error);
//         });
//     });
// }









// //加上进度条
// export function transfer(serial, filename, buffer, logger = console.log, progressCallback) {
//   if (!serial) {
//     throw new Error("Serial object is null or undefined.");
//   }

//   return new Promise((resolve) => {
//     var file_trunks = [];
//     var totalBytes = 0;
//     var writtenBytes = 0;
//     var seq = 0;
//     var session = false;
//     var sending = false;
//     var finished = false;

//     buffer = Buffer.from(buffer.buffer);

//     async function sendBuffer(buffer, once_len = 0) {
//       if (!once_len) {
//         return await serial.writable.getWriter().write(buffer);
//       }
//       async function bulk() {
//         var chunks = splitBuffer(buffer, once_len);
//         for (const chunk of chunks) {
//           var arr = new Uint8Array(chunk.buffer);
//           await serial.writable.getWriter().write(arr);
//         }
//       }
//       return await bulk();
//     }

//     async function sendPacket() {
//       logger(`sendPacket seq:${seq}/${file_trunks.length}  \r`);
//       if (seq < file_trunks.length) {
//         var packet = file_trunks[seq];
//         await sendBuffer(packet);
//       } else {
//         if (sending) {
//           await sendBuffer(Buffer.from([EOT]));
//         }
//       }
//     }

//     function handler(data) {
//       let PreChar = 0;
//       for (var i = 0; i < data.byteLength; i++) {
//         if (!finished) {
//           var ch = data[i];
//           if (ch === CRC16) {
//             logger(`RCV: C @${seq}`);
//             if (seq >= file_trunks.length) {
//               logger(`SEND EOT @${seq}`);
//               sendBuffer(eot_pack);
//             } else if (PreChar != CRC16) {
//               sendPacket();
//               sending = true;
//             }
//           } else if (ch === ACK) {
//             logger(`RCV: ACK @${seq}`);
//             if (!session) {
//               close();
//             }
//             if (sending) {
//               if (seq == 0) {
//                 seq++;
//               } else if (seq < file_trunks.length) {
//                 if (writtenBytes < totalBytes) {
//                   writtenBytes = (seq + 1) * PACKET_SIZE_1024;
//                   if (writtenBytes > totalBytes) {
//                     writtenBytes = totalBytes;
//                   }
//                 }
//                 seq++;
//                 if (progressCallback) {
//                   progressCallback((writtenBytes / totalBytes) * 100);
//                 }
//                 sendPacket();
//               } else {
//                 sending = false;
//                 session = false;
//                 logger(`SEND EOT @${seq}`);
//                 sendBuffer(eot_pack);
//               }
//             }
//           } else if (ch === NAK) {
//             sendPacket();
//           } else if (ch === CA) {
//             logger(`RCV: CA @${seq}`);
//             close("CA");
//           }
//           PreChar = ch;
//         }
//       }
//     }

//     function close(ch = '') {
//       session = false;
//       sending = false;
//       serial.readable.getReader().releaseLock();
//       logger(`CLOSE BY [${ch}]`);
//       if (!finished) {
//         const result = {
//           filePath: filename,
//           totalBytes: totalBytes,
//           writtenBytes: writtenBytes,
//         };
//         resolve(result);
//       }
//       finished = true;
//     }

//     totalBytes = buffer.byteLength;
//     var headerPayload = makeFileHeader(filename, totalBytes);
//     file_trunks.push(headerPayload);

//     var payloads = splitFile(buffer);
//     payloads.forEach((payload) => {
//       file_trunks.push(payload);
//     });

//     session = true;

//     const reader = serial.readable.getReader();
//     async function readLoop() {
//       for (;;) {
//         const { value, done } = await reader.read();
//         if (done) {
//           break;
//         }
//         if (value) {
//           handler(value);
//         }
//       }
//     }
//     readLoop();
//   });
// }



//{升级是成功但是会报错，未关闭锁}
import { Buffer } from 'buffer';

const PACKET_SIZE_128 = 128;
const PACKET_SIZE_1024 = 1024;
const SOH = 0x01;
const STX = 0x02;
const EOT = 0x04;
const ACK = 0x06;
const NAK = 0x15;
const CA = 0x18;
const CRC16 = 0x43;

var eot_pack = Buffer.alloc(PACKET_SIZE_128 + 5, 0x00);
eot_pack[0] = SOH;
eot_pack[2] = 0xff;

function crc16xmodem(packet, begin, len, previous) {
    let stop_at = begin + len;
    let crc = typeof previous !== 'undefined' ? ~~previous : 0x0;
    for (; begin < stop_at; begin++) {
        let code = (crc >>> 8) & 0xff;
        code ^= packet[begin] & 0xff;
        code ^= code >>> 4;
        crc = (crc << 8) & 0xffff;
        crc ^= code;
        code = (code << 5) & 0xffff;
        crc ^= code;
        code = (code << 7) & 0xffff;
        crc ^= code;
    }
    return crc;
}

function makeFileHeader(filename, filesize) {
    let File_HD_SIZE = 128;
    var payload = Buffer.alloc(File_HD_SIZE + 3 + 2, 0x00);
    payload[0] = SOH;
    payload[1] = 0;
    payload[2] = 0xff;
    var offset = 3;
    if (filename) {
        payload.write(filename, offset);
        offset += filename.length + 1;
    }
    if (filesize) {
        payload.write(filesize.toString() + " ", offset);
    }
    var crc = crc16xmodem(payload, 3, File_HD_SIZE);
    payload.writeUInt16BE(crc, payload.byteLength - 2);
    return payload;
}

function splitFile(buffer) {
    let totalBytes = buffer.byteLength;
    let maxPack = parseInt((buffer.byteLength + PACKET_SIZE_1024 - 1) / PACKET_SIZE_1024);
    var array = [];
    for (let i = 0; i < maxPack; i++) {
        let is_last = (i + 1) == maxPack ? true : false;
        let packSize = PACKET_SIZE_1024;
        if (is_last && totalBytes - i * PACKET_SIZE_1024 <= 128) {
            packSize = PACKET_SIZE_128;
        }
        var chunk = Buffer.alloc(packSize + 3 + 2, is_last ? 0x1A : 0x00);

        chunk[0] = (packSize == PACKET_SIZE_1024) ? STX : SOH;
        chunk[1] = (i + 1) & 0xff;
        chunk[2] = 0xff - chunk[1];

        buffer.copy(chunk, 3, PACKET_SIZE_1024 * i, PACKET_SIZE_1024 * i + packSize);
        var crc = crc16xmodem(chunk, 3, packSize);
        chunk.writeUInt16BE(crc, chunk.byteLength - 2);
        array.push(chunk);
    }
    return array;
}

function splitBuffer(buffer, size) {
    let totalBytes = buffer.byteLength;
    let maxPack = parseInt((buffer.byteLength + size - 1) / size);
    var array = [];
    for (let i = 0; i < maxPack; i++) {
        let is_last = (i + 1) == maxPack ? true : false;
        let packSize = size;
        if (is_last) {
            packSize = totalBytes % size;
        }
        var chunk = Buffer.alloc(packSize, 0x00);
        buffer.copy(chunk, 0, size * i, size * i + packSize);
        array.push(chunk);
    }
    return array;
}

export function transfer({ reader, writer }, filename, buffer, logger = console.log, progressCallback) {
    return new Promise( (resolve, reject) => {
        var file_trunks = [];
        var totalBytes = 0;
        var writtenBytes = 0;
        var seq = 0;
        var session = false;
        var sending = false;
        var finished = false;

        buffer = Buffer.from(buffer.buffer);

        async function sendBuffer(buffer, once_len = 0) {
            if (!once_len) {
                logger(`sendBuffer: Writing buffer of length ${buffer.length}`);
                await writer.write(buffer);
                return;
            }
            async function bulk() {
                var chunks = splitBuffer(buffer, once_len);
                for (const chunk of chunks) {
                    var arr = new Uint8Array(chunk.buffer);
                    await writer.write(arr);
                    logger(`sendBuffer: Writing chunk of length ${chunk.length}`);
                }
            }
            return await bulk();
        }

        async function sendPacket() {
            logger(`sendPacket seq:${seq}/${file_trunks.length}`);
            if (seq < file_trunks.length) {
                var packet = file_trunks[seq];
                await sendBuffer(packet);
            } else {
                if (sending) {
                    await sendBuffer(Buffer.from([EOT]));
                    logger(`sendPacket: Sent EOT`);
                }
            }
        }

        async function readData() {
            let PreChar = 0;
            try {
                /* eslint-disable no-constant-condition */
                while (true) {
                    const { value, done } = await reader.read();
                    if (done) {
                        break;
                    }
                    for (let i = 0; i < value.byteLength; i++) {
                        if (!finished) {
                            var ch = value[i];
                            logger(`RCV: ${String.fromCharCode(ch)} (${ch}) @${seq}`);
                            if (ch === CRC16) {
                                logger(`RCV: C @${seq}`);
                                if (seq >= file_trunks.length) {
                                    logger(`SEND EOT @${seq}`);
                                    sendBuffer(eot_pack);
                                } else if (PreChar != CRC16) {
                                    sendPacket();
                                    sending = true;
                                }
                            } else if (ch === ACK) {
                                logger(`RCV: ACK @${seq}`);
                                if (!session) {
                                    close();
                                }
                                if (sending) {
                                    if (seq == 0) { // HEADER ACK; DATA PACK followed by next C
                                        seq++;
                                    } else if (seq < file_trunks.length) {
                                        if (writtenBytes < totalBytes) {
                                            writtenBytes = (seq + 1) * PACKET_SIZE_1024;
                                            if (writtenBytes > totalBytes) {
                                                writtenBytes = totalBytes;
                                            }
                                            // onProgress && onProgress(writtenBytes / totalBytes);
                                        }
                                        seq++;
                                        if (progressCallback) {
                                             progressCallback((writtenBytes / totalBytes) * 100);
                                          }
                                        sendPacket();
                                    } else {
                                        sending = false;
                                        session = false;
                                        logger(`SEND EOT @${seq}`);
                                        sendBuffer(eot_pack);
                                    }
                                }
                            } else if (ch === NAK) {
                                logger(`RCV: NAK @${seq}`);
                                sendPacket();
                            } else if (ch === CA) {
                                logger(`RCV: CA @${seq}`);
                                close("CA");
                            }
                            PreChar = ch;
                        }
                    }
                }
            } catch (error) {
                reject(error);
            } finally {
                reader.releaseLock();
            }
        }

        function close(ch = '') {
            session = false;
            sending = false;
            if (reader.locked) {
                reader.cancel().then(() => {
                    logger(`CLOSE BY [${ch}]`);
                    finish();
                });
            } else {
                logger(`CLOSE BY [${ch}]`);
                finish();
            }
        }

        function finish() {
            if (!finished) {
                const result = {
                    filePath: filename,
                    totalBytes: totalBytes,
                    writtenBytes: writtenBytes,
                };
                resolve(result);
            }
            finished = true;
        }

        totalBytes = buffer.byteLength;
        var headerPayload = makeFileHeader(filename, totalBytes);
        file_trunks.push(headerPayload);
        logger("File header created");

        var payloads = splitFile(buffer);
        payloads.forEach((payload) => {
            file_trunks.push(payload);
        });
        logger("File data packets created");

        session = true;
        readData();
        logger("YModem transfer session started");
    });
}



// // ymodem.js
// import { Buffer } from 'buffer';

// const PACKET_SIZE_128 = 128;
// const PACKET_SIZE_1024 = 1024;
// const SOH = 0x01;
// const STX = 0x02;
// const EOT = 0x04;
// const ACK = 0x06;
// const NAK = 0x15;
// const CA = 0x18;
// const CRC16 = 0x43;

// var eot_pack = Buffer.alloc(PACKET_SIZE_128 + 5, 0x00);
// eot_pack[0] = SOH;
// eot_pack[2] = 0xff;

// function crc16xmodem(packet, begin, len, previous) {
//     let stop_at = begin + len;
//     let crc = typeof previous !== 'undefined' ? ~~previous : 0x0;
//     for (; begin < stop_at; begin++) {
//         let code = (crc >>> 8) & 0xff;
//         code ^= packet[begin] & 0xff;
//         code ^= code >>> 4;
//         crc = (crc << 8) & 0xffff;
//         crc ^= code;
//         code = (code << 5) & 0xffff;
//         crc ^= code;
//         code = (code << 7) & 0xffff;
//         crc ^= code;
//     }
//     return crc;
// }

// function makeFileHeader(filename, filesize) {
//     let File_HD_SIZE = 128;
//     var payload = Buffer.alloc(File_HD_SIZE + 3 + 2, 0x00);
//     payload[0] = SOH;
//     payload[1] = 0;
//     payload[2] = 0xff;
//     var offset = 3;
//     if (filename) {
//         payload.write(filename, offset);
//         offset += filename.length + 1;
//     }
//     if (filesize) {
//         payload.write(filesize.toString() + " ", offset);
//     }
//     var crc = crc16xmodem(payload, 3, File_HD_SIZE);
//     payload.writeUInt16BE(crc, payload.byteLength - 2);
//     return payload;
// }

// function splitFile(buffer) {
//     let totalBytes = buffer.byteLength;
//     let maxPack = parseInt((buffer.byteLength + PACKET_SIZE_1024 - 1) / PACKET_SIZE_1024);
//     var array = [];
//     for (let i = 0; i < maxPack; i++) {
//         let is_last = (i + 1) == maxPack ? true : false;
//         let packSize = PACKET_SIZE_1024;
//         if (is_last && totalBytes - i * PACKET_SIZE_1024 <= 128) {
//             packSize = PACKET_SIZE_128;
//         }
//         var chunk = Buffer.alloc(packSize + 3 + 2, is_last ? 0x1A : 0x00);

//         chunk[0] = (packSize == PACKET_SIZE_1024) ? STX : SOH;
//         chunk[1] = (i + 1) & 0xff;
//         chunk[2] = 0xff - chunk[1];

//         buffer.copy(chunk, 3, PACKET_SIZE_1024 * i, PACKET_SIZE_1024 * i + packSize);
//         var crc = crc16xmodem(chunk, 3, packSize);
//         chunk.writeUInt16BE(crc, chunk.byteLength - 2);
//         array.push(chunk);
//     }
//     return array;
// }

// function splitBuffer(buffer, size) {
//     let totalBytes = buffer.byteLength;
//     let maxPack = parseInt((buffer.byteLength + size - 1) / size);
//     var array = [];
//     for (let i = 0; i < maxPack; i++) {
//         let is_last = (i + 1) == maxPack ? true : false;
//         let packSize = size;
//         if (is_last) {
//             packSize = totalBytes % size;
//         }
//         var chunk = Buffer.alloc(packSize, 0x00);
//         buffer.copy(chunk, 0, size * i, size * i + packSize);
//         array.push(chunk);
//     }
//     return array;
// }

// export function transfer({ reader, writer }, filename, buffer, logger = console.log, onProgress) {
//     return new Promise( (resolve, reject) => {
//         var file_trunks = [];
//         var totalBytes = 0;
//         var writtenBytes = 0;
//         var seq = 0;
//         var session = false;
//         var sending = false;
//         var finished = false;

//         buffer = Buffer.from(buffer.buffer);

//         async function sendBuffer(buffer, once_len = 0) {
//             if (!once_len) {
//                 logger(`sendBuffer: Writing buffer of length ${buffer.length}`);
//                 await writer.write(buffer);
//                 return;
//             }
//             async function bulk() {
//                 var chunks = splitBuffer(buffer, once_len);
//                 for (const chunk of chunks) {
//                     var arr = new Uint8Array(chunk.buffer);
//                     await writer.write(arr);
//                     logger(`sendBuffer: Writing chunk of length ${chunk.length}`);
//                 }
//             }
//             return await bulk();
//         }

//         async function sendPacket() {
//             logger(`sendPacket seq:${seq}/${file_trunks.length}`);
//             if (seq < file_trunks.length) {
//                 var packet = file_trunks[seq];
//                 await sendBuffer(packet);
//             } else {
//                 if (sending) {
//                     await sendBuffer(Buffer.from([EOT]));
//                     logger(`sendPacket: Sent EOT`);
//                 }
//             }
//         }

//         async function readData() {
//             let PreChar = 0;
//             try {
//                 /* eslint-disable no-constant-condition */
//                 while (true) {
//                     const { value, done } = await reader.read();
//                     if (done) {
//                         break;
//                     }
//                     for (let i = 0; i < value.byteLength; i++) {
//                         if (!finished) {
//                             var ch = value[i];
//                             logger(`RCV: ${String.fromCharCode(ch)} (${ch}) @${seq}`);
//                             if (ch === CRC16) {
//                                 logger(`RCV: C @${seq}`);
//                                 if (seq >= file_trunks.length) {
//                                     logger(`SEND EOT @${seq}`);
//                                     await sendBuffer(eot_pack);
//                                 } else if (PreChar != CRC16) {
//                                     await sendPacket();
//                                     sending = true;
//                                 }
//                             } else if (ch === ACK) {
//                                 logger(`RCV: ACK @${seq}`);
//                                 if (!session) {
//                                     await close();
//                                 }
//                                 if (sending) {
//                                     if (seq == 0) { // HEADER ACK; DATA PACK followed by next C
//                                         seq++;
//                                     } else if (seq < file_trunks.length) {
//                                         if (writtenBytes < totalBytes) {
//                                             writtenBytes = (seq + 1) * PACKET_SIZE_1024;
//                                             if (writtenBytes > totalBytes) {
//                                                 writtenBytes = totalBytes;
//                                             }
//                                             onProgress && onProgress(writtenBytes / totalBytes);
//                                         }
//                                         seq++;
//                                         await sendPacket();
//                                     } else {
//                                         sending = false;
//                                         session = false;
//                                         logger(`SEND EOT @${seq}`);
//                                         await sendBuffer(eot_pack);
//                                     }
//                                 }
//                             } else if (ch === NAK) {
//                                 logger(`RCV: NAK @${seq}`);
//                                 await sendPacket();
//                             } else if (ch === CA) {
//                                 logger(`RCV: CA @${seq}`);
//                                 await close("CA");
//                             }
//                             PreChar = ch;
//                         }
//                     }
//                 }
//             } catch (error) {
//                 reject(error);
//             } finally {
//                 reader.releaseLock();
//             }
//         }

//         async function close(ch = '') {
//             session = false;
//             sending = false;
//             if (reader.locked) {
//                 await reader.cancel();
//             }
//             logger(`CLOSE BY [${ch}]`);
//             finish();
//         }

//         function finish() {
//             if (!finished) {
//                 const result = {
//                     filePath: filename,
//                     totalBytes: totalBytes,
//                     writtenBytes: writtenBytes,
//                 };
//                 resolve(result);
//             }
//             finished = true;
//         }

//         totalBytes = buffer.byteLength;
//         var headerPayload = makeFileHeader(filename, totalBytes);
//         file_trunks.push(headerPayload);
//         logger("File header created");

//         var payloads = splitFile(buffer);
//         payloads.forEach((payload) => {
//             file_trunks.push(payload);
//         });
//         logger("File data packets created");

//         session = true;
//         readData();
//         logger("YModem transfer session started");
//     });
// }

