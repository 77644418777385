<template>
    <div class="swiperbox">
      <swiper
        :slides-per-view="1" 
        :space-between="30"
        :loop="true"
        :centered-slides="true"
        :pagination="paginationOptions"
        :modules="modules"
        class="mySwiper"
      >
        <!-- 根据当前要显示的幻灯片数量动态渲染幻灯片 -->
        <swiper-slide v-for="(image, index) in imagesToShow" :key="index">
          <img :src="image.src" alt="">
        </swiper-slide>
      </swiper>
  
      <!-- 按钮，用于修改显示的幻灯片数量 -->
      <div class="controls">
        <button @click="showNextSlide">显示下一张</button>
      </div>
    </div>
  </template>
  
  <script>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Navigation, Pagination, A11y, Mousewheel } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  
  export default {
    name: 'MySwiperComponent', // 设置组件名称
  
    components: {
      Swiper,
      SwiperSlide,
    },
  
    data() {
      return {
        slidesToShow: 1, // 初始显示的幻灯片数量
        images: [
          { src: require('@/assets/y1.png'), id: 'image1', text: '页面一' },
          { src: require('@/assets/y1.png'), id: 'image2', text: '页面二' },
          { src: require('@/assets/y2.png'), id: 'image3', text: '页面三' },
          // { src: 'path/to/image4.jpg', id: 'image4' } // 可以添加更多图像
        ],
        modules: [Navigation, Pagination, A11y, Mousewheel], // Swiper 模块
        paginationOptions: {
          clickable: true,
        },
      };
    },
  
    computed: {
      // 根据 slidesToShow 的值计算当前显示的幻灯片数量
      imagesToShow() {
        return this.images.slice(0, this.slidesToShow);
      },
    },
  
    methods: {
      showNextSlide() {
        if (this.slidesToShow < this.images.length) {
          this.slidesToShow += 1; // 每次点击按钮，显示的图片数量加1
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .swiperbox {
    width: 100%;
    height: 500px; /* 设置一个合适的高度，例如 300px */
    display: flex;
    flex-direction: column;
    align-items: center; /* 居中对齐内容 */
  }
  
  .mySwiper {
    width: 100%;
    height: 100%; /* 确保 Swiper 组件充满容器 */
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px; /* 根据需要调整字体大小 */
    background-color: #f0f0f0; /* 设置一个背景色以便更容易看到 */
    height: 100%; /* 确保每个 slide 充满 Swiper 容器 */
  }
  
  .controls {
    margin-top: 20px; /* 给按钮一些上边距 */
  }
  </style>
  