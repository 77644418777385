import Vuex from 'vuex';
import Cookies from 'js-cookie';
export default new Vuex.Store({
  state: {
    serialPort: null,
    writer: null,
    reader: null,
    serialData: [],
    deviceInfo: null, // 用于存储设备信息
    isConnected: false,
    isPortOpen: false,
    pendingRequests: {},

  },
  mutations: {
    SET_SERIAL_PORT(state, port) {
      state.serialPort = port;
      state.isConnected = true;
    },
    SET_WRITER(state, writer) {
      if (state.writer) {
        state.writer.releaseLock(); // 释放现有的 writer
      }
      state.writer = writer;
    },
    SET_READER(state, reader) {
      if (state.reader) {
        state.reader.releaseLock(); // 释放现有的 reader
      }
      state.reader = reader;
    },
    ADD_SERIAL_DATA(state, data) {
      state.serialData.push(data);
    },
    SET_DEVICE_INFO(state, deviceInfo) {
      state.deviceInfo = deviceInfo;
    },
    CLEAR_SERIAL_DATA(state) {
      state.serialData = [];
    },
    async DISCONNECT_SERIAL(state) {
      try {
        if (state.writer) {
          await state.writer.close(); // 确保 writer 关闭
          state.writer.releaseLock(); // 释放 writer 锁
        }
        if (state.reader) {
          await state.reader.cancel(); // 确保 reader 停止读取
          state.reader.releaseLock(); // 释放 reader 锁
        }
        if (state.serialPort) {
          await state.serialPort.close(); // 关闭串口
        }
      } catch (error) {
        console.error('关闭串口时出错:', error);
      } finally {
        state.serialPort = null;
        state.writer = null;
        state.reader = null;
        state.isConnected = false;
        state.isPortOpen = false;
      }
    },
    SET_IS_PORT_OPEN(state, isOpen) {
      state.isPortOpen = isOpen;
    },
    ADD_PENDING_REQUEST(state, { id, resolve, reject }) {
      state.pendingRequests[id] = { resolve, reject };
    },
    REMOVE_PENDING_REQUEST(state, id) {
      delete state.pendingRequests[id];
    },

  },
  actions: {
    async connectSerial({ commit, state, dispatch }) {
      try {
        if (state.isPortOpen) {
          alert("已连接！请勿重复连接！");
          return;
        }
        const port = await navigator.serial.requestPort({});
        if (!port) {
          throw new Error('用户取消了串口选择');
        }
        await port.open({ baudRate: 921600 });

        commit('SET_SERIAL_PORT', port);
        const writer = port.writable.getWriter();
        commit('SET_WRITER', writer);
        const reader = port.readable.getReader();
        commit('SET_READER', reader);

        commit('SET_IS_PORT_OPEN', true);

        // 发送设备信息请求
        const m = {
          method: "get_device_info"
        };
         dispatch('sendSerialData', m);
        dispatch('readSerialData'); // 开始读取数据
      } catch (error) {
        if (error.name === 'AbortError') {
          alert("请选择一个串口设备以继续。");
        } else {
          alert("串口打开失败，请刷新后再试！");
          console.error('串口连接失败:', error);
        }
        commit('DISCONNECT_SERIAL');
      }
    },
    async readSerialData({ commit, state, dispatch }) {

      const { serialPort } = state;
      if (!serialPort) return;

      if (state.reader) {
        state.reader.releaseLock(); // 释放现有的 reader
      }
      const reader = serialPort.readable.getReader();
      commit('SET_READER', reader);

      const readLoop = async () => {
        let buffer = '';
        let openBraces = 0;

        while (state.isConnected) {
          try {
            const { value, done } = await reader.read();
            if (done) {
              break;
            }
            if (value) {
              const textDecoder = new TextDecoder();
              const data = textDecoder.decode(value);
              buffer += data;
              console.log ('接收到数据:', buffer);
              for (let i = 0; i < data.length; i++) {
                const char = data[i];
                if (char === '{') {
                  openBraces++;
                } else if (char === '}') {
                  openBraces--;
                }
                if (openBraces === 0 && buffer.trim()) {
                  // if (openBraces === 0 && buffer.includes('"method"')) {
                    try {
                      // const jsonStr = buffer.trim();
                      const jsonStr = buffer.trim(); // 提取完整的 JSON 字符串
                      const jsonData = JSON.parse(jsonStr);
                      console.log ('接收到数据:Js', jsonData);
                      commit('ADD_SERIAL_DATA', jsonData);
                      // 检查是否有匹配的挂起请求

                      if (jsonData.id && state.pendingRequests[jsonData.id]) {
                        state.pendingRequests[jsonData.id].resolve(jsonData);
                        commit('REMOVE_PENDING_REQUEST', jsonData.id);
                      }
                      if (jsonData.method === "get_device_info_reply") {
                        commit('SET_DEVICE_INFO', jsonData);
                        Cookies.set('deviceInfo', JSON.stringify(jsonData));
                      }
                    } catch (error) {
                      console.error('解析JSON错误:', error, 'JSON字符串:', buffer.trim());
                      
                    }
                   buffer = '';
                }
              }
            }
          } catch (error) {
            if (error.name === 'NetworkError' || error.name === 'AbortError' || error.name === 'DOMException') {
              alert('设备已断开连接');
              console.error('设备已断开连接');
              dispatch('disconnectSerial');
              break;
            } else {
              console.error('读取数据失败:', error);
            }
          }
        }
      };
      readLoop();
    },
     sendSerialData({ commit, state }, data) {
      if (!state.serialPort || !state.serialPort.writable) {
        console.error('串口未打开或不可写');
        return;
      }
      return new Promise((resolve, reject) => {
        if (data.id) {
          commit('ADD_PENDING_REQUEST', { id: data.id, resolve, reject });
        }
        const jsonString = JSON.stringify(data) + "\n";
        const encoder = new TextEncoder();
        const writer = state.writer;
        if (!writer) {
          console.error('Writer is not initialized.');
          reject(new Error('Writer is not initialized.'));
          return;
        }
        const encodedData = encoder.encode(jsonString);
        console.log('发送数据:', jsonString);
        writer.write(encodedData).catch(error => {
          console.error('发送数据失败:', error);
          reject(error);
        });
      });
    },
    disconnectSerial({ commit }) {
      commit('DISCONNECT_SERIAL');
    },
  },
  getters: {
    getSerialData: state => state.serialData,
    getIsConnected: state => state.isConnected,
    getSerialPort: state => state.serialPort,
    getWriter: state => state.writer,
    getReader: state => state.reader,
    getIsPortOpen: state => state.isPortOpen,
    getDeviceInfo: state => state.deviceInfo, // 获取设备信息
  },
});
