<template>
  <div class="header-flex">
    <div class="header">
      <h2>智芯之家</h2>
    </div>
    <div class="nav-items">
      <h4 class="gap" @click="connectSerial" :class="{ 'connected-style': isConnectedStyleChanged }"><i class="fas fa-plus"></i>{{isH4text}}</h4>
      <!-- <h4 class="gap" style="color: gray">|</h4>
      <h4 class="gap"><i class="fas fa-user"></i></h4>
      <h4 class="gap"><i class="fas fa-cog"></i></h4> -->
    </div>
  </div>
  <div class="gallery">
    <div class="image-container" v-if="showPointInsideText" @click="navigateToAbout">
      <img :src="image.src" :class="{ active: true, inactive: false }" alt="图片2">
      <div class="description">
        <p class="text-line">智芯X.PAD</p>
        <p class="icon-line">
          <span style="color: rgb(0, 245, 0)" id="batteryPercentage">{{batteryLevelTxt}}% </span>
          <i class="fas fa-battery-half" style="color: rgb(6, 255, 6);"></i>
          <i><svg t="1720085179326" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2272" width="16" height="16"><path d="M932.352 310.272L434.688 0v402.944L145.92 222.72 91.648 307.2l324.096 204.8-324.096 204.8 54.272 87.04 288.768-180.224V1024l497.664-310.784L609.28 512zM537.088 184.32l201.728 125.952-201.728 125.952zM614.4 636.928l122.88 76.8-200.192 125.952v-251.904z" fill="#333333" p-id="2273"></path></svg></i>
        </p>
      </div>
    </div>
    <!-- <div class="image-txt" v-else>
      <span>当前无设备连接！请先添加设备！</span>
    </div> -->
    <div class="image-container" v-else>
      <img :src="image.src" :class="{ active: true, inactive: false }" alt="图片2">
      <div class="description">
        <p class="text-line">智芯X.PAD</p>
        <p class="image-txt">当前无设备连接！请先添加设备！</p>
        <p class="icon-line">
          <!-- <span style="color: rgb(0, 245, 0)" id="batteryPercentage">{{batteryLevelTxt}}% </span>
          <i class="fas fa-battery-half" style="color: rgb(6, 255, 6);"></i>
          <i><svg t="1720085179326" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2272" width="16" height="16"><path d="M932.352 310.272L434.688 0v402.944L145.92 222.72 91.648 307.2l324.096 204.8-324.096 204.8 54.272 87.04 288.768-180.224V1024l497.664-310.784L609.28 512zM537.088 184.32l201.728 125.952-201.728 125.952zM614.4 636.928l122.88 76.8-200.192 125.952v-251.904z" fill="#333333" p-id="2273"></path></svg></i>
        </p> -->
      </p> 
      </div>
    </div>
  </div>
</template>
<script>
// import { useRouter } from 'vue-router'; 
import { mapGetters , mapActions } from 'vuex';
// import { mapState, mapActions } from 'vuex';
export default {
  name: 'LogNavComponent', 
  data() {
    return {
      showPointInsideText: false,  //默认为false 测试不连接设备为true
      image: {
        src: require('@/assets/9.png'),
        isActive: false,
      },
      isConnected: false,
      isH4text : '添加设备',
      isConnectedStyleChanged: false,
      batteryLevelTxt: 50,
    };
  },
  mounted() {
    this.refreshPage();
  },
  computed: {
       ...mapGetters(['getSerialData',   'getDeviceInfo']),
  },
  methods: {
    // ...mapActions(['connectSerial']),
    ...mapActions([
      'connectSerial',
      'disconnectSerial',
      'sendSerialData',
      'readSerialData'
    ]),
      async connectSeriaal() {
          this.connectSerial;
      },
    async  onConnectSuccess(){
        this.showPointInsideText=true;
        this.image.isActive = true; 
        this.isH4text = "已连接";
        this.isConnectedStyleChanged = true;
        const Device=  this.getDeviceInfo;
        this.batteryLevelTxt= Device.battery_level;
    },
      navigateToAbout(event) {
      const target = event.target;
      const container = target.closest('.image-container');
      if (container) {
        console.log('图片容器被点击');
        this.$router.push('/upgradePage');
        // this.$router.push('/test');
      } else {
        console.log('点击的不是图片容器');
      }
    },
    refreshPage() {
      // 实现你的刷新逻辑，例如重新获取数据
      console.log('首页刷新');
      // 这里可以添加任何需要的刷新逻辑
    },
    // handleBeforeUnload(event) {
    //   const message = '您确定要离开此页面吗？';
    //   event.returnValue = message; // 兼容性设置
    //   return message;
    // }
},

  watch: {
     '$store.getters.getSerialData': {
       handler(newVal) {
         if (newVal.length > 0) {
         this.onConnectSuccess();
         }
       },
       immediate: true, // 立即执行一次
       deep: true, // 深度监听，适用于对象或数组的变化
     },
   },
};



</script>
<style scoped>
/* Your component-specific styles here */
.header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.connected-style {
  color: rgb(72, 255, 0); /* 修改文字颜色 */
 
}
.nav-items {
  display: flex;
  gap: 10px;
  padding-top: 12px;
}

.gap {
  margin: 0 10px;
}

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  position: relative;
  width: 500px; /* Adjust as needed */
  height: 600px; /* Adjust as needed */
  overflow: hidden;
  cursor: pointer;
}

.image-txt {
  /* padding-top: 200px; */
  color: red;
  font-size: 25px;
  font-weight: bold;
}

.active {
  opacity: 1;
}

.inactive {
  opacity: 0.5;
}

.description {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  margin-bottom: -30px;
  background-color: rgba(216, 216, 216, 0.5);
  color: #727272;
  text-align: center;
  font-weight: bold;
}

.text-line {
  margin-bottom: 5px;
}

.icon-line {
  font-size: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 80px;
  padding-top: 0px;
  margin-top: -12px;
}
</style>
