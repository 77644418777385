import { createRouter, createWebHistory } from 'vue-router';
import indexPage  from '../components/index.vue';
import upgradePage from '../components/upgradePage.vue';
import settingsPage from '../components/settingsPage.vue';
import tsetPage from '../components/test.vue';
const routes = [
  {
    path: '/',
    component: indexPage,
  },

  {
    path: '/upgradePage',
    component: upgradePage,
  },
  // {
  //   path: '/settingsPage',
  //   component: settingsPage,
  // },
  {
    path: '/settingsPage/:imageId', // 定义了参数 imageId
    name: 'settingsPage',
    component: settingsPage
  },
  {
    path: '/test',
    component: tsetPage,
  },
  // 添加更多路由
];

const router = createRouter({
  history: createWebHistory(),
  routes
});
export default router;