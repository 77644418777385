import { createApp } from 'vue'
import App from './App.vue'
import router from './router'; //
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 引入Element Plus的样式
// import { ElMessageBox } from 'element-plus';

const app= createApp(App);


// app.config.globalProperties.$msgBox = ElMessageBox; // 将ElMessageBox添加到全局属性中

// export { app.config.globalProperties.$msgBox as $msgBox };
// export { app.config.globalProperties.$msgBox as $msgBox }

app.use(router); 
app.use(store);
app.use(ElementPlus);


app.mount('#app');

