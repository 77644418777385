<template>
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h3>{{ title }}</h3>
        <ul class="response-list">
          <li v-for="(item, index) in responseData" :key="index">
            <label>
              <input type="radio" :value="item" v-model="selectedItem" />
              {{ item.displayText }}
            </label>
          </li>
          <!-- <li v-for="(item, index) in responseData" :key="index">
          
          </li> -->
        </ul>
        <button @click="confirmSelection">确定</button>
        <button @click="closeModal">关闭</button>
      </div>
    </div>

  
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        default: '响应数据',
      },
      responseData: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        showModal: true,
        selectedItem: null,
      };
    },
    methods: {
      confirmSelection() {
        this.$emit('confirm', this.selectedItem);
        this.showModal = false;
        this.$emit('close');
      },
      closeModal() {
        this.showModal = false;
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
  }
  .response-list {
    list-style-type: none;
    padding: 0;
  }
  .response-list li {
    margin-bottom: 10px;
  }
  </style>
  