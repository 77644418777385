<template>
  <div class="container">
    <div class="left-half">
      <div class="header2">
        <router-link class="jiantou" to="/upgradePage" style="text-decoration: none; color: black;">
          <h2>&lt;</h2>
        </router-link>
      </div>
      <div class="img-div2">
        <p class="img-text">{{ imgName}}</p>
        <canvas ref="myCanvas" width="600" height="700"></canvas>
      </div>
      <div class="nav-items2">
        <p class="icon-line2">
          <span :style="{ color: batteryColor }">{{ batteryPercentage }}%</span>
          <i class="fas fa-battery-half" :style="{ color: batteryIconColor }"></i>
          <i><svg :class="iconClass" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="16"
              height="16">
              <path :d="iconPath" fill="#333333"></path>
            </svg></i>
        </p>
      </div>
    </div>
    <div class="right-half">
      <div class="white-content2">
        <div class="header2">
          <h3>{{ settingsTitle }}</h3>
        </div>
        <div class="list-container">
          <ul class="ule">
            <li>推荐</li>
            <li v-for="(item, index) in recommendedList" :key="index">
              <i class="fas fa-toggle-off switch-icon" v-if="!item.enabled"></i>
              <i class="fas fa-toggle-on switch-icon" v-else></i>
              {{ item.keyCombination }}
            </li>
          </ul>
          <ul class="ulea">
            <li>历史</li>
            <li v-for="(item, index) in historyList" :key="index">
              <i class="fas fa-toggle-off switch-icon" v-if="!item.enabled"></i>
              <i class="fas fa-toggle-on switch-icon" v-else></i>
              {{ item.keyCombination }}
            </li>
          </ul>
        </div>
        <div :style="{ borderBottom: '1px solid rgb(215, 215, 215)', paddingTop: '110px' }"></div>
        <div class="switch-list">
          <h3>{{ customTitle }}</h3>
        </div>
        <div class="input-group">
          <input type="text" v-model="input1" placeholder="输入框1" @keydown="handleKeydown('input1', $event)">
          <input type="text" v-model="input2" placeholder="输入框2" @keydown="handleKeydown('input2', $event)">
          <input type="text" v-model="input3" placeholder="输入框3" @keydown="handleKeydown('input3', $event)">
          <input type="checkbox" v-model="customEnabled" @change="updateRecommendedList" id="customCheckbox">
      <label for="customCheckbox">是否启用</label>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import { ElMessageBox } from 'element-plus';
export default {
  data() {
    return {
      imageSrc: {
        src: require('@/assets/9.png'),
      },
      imgName:'',
      img: null,
      canvas: null,
      ctx: null,
      batteryPercentage: 50,
      batteryColor: 'rgb(0, 245, 0)',
      batteryIconColor: 'rgb(6, 255, 6)',
      iconPath: 'M932.352 310.272L434.688 0v402.944L145.92 222.72 91.648 307.2l324.096 204.8-324.096 204.8 54.272 87.04 288.768-180.224V1024l497.664-310.784L609.28 512zM537.088 184.32l201.728 125.952-201.728 125.952zM614.4 636.928l122.88 76.8-200.192 125.952v-251.904z',
      iconClass: 'icon',
      settingsTitle: '键位设置',
      recommendedList: [
        { keyCombination: 'Ctrl+v', enabled: false },
        { keyCombination: 'Ctrl+Shift+c', enabled: false },
        { keyCombination: 'Shift+o', enabled: false },
        { keyCombination: 'Ctrl+]', enabled: false },
        { keyCombination: 'Ctrl+Shift+]', enabled: false },
      ],
      historyList: [
        // { keyCombination: 'Ctrl+V', enabled: false },
        // { keyCombination: 'Ctrl+Shift+V', enabled: false },
        // { keyCombination: 'Shift+O', enabled: false },
        // { keyCombination: 'Ctrl+]', enabled: false },
        // { keyCombination: 'Ctrl+Shift+]', enabled: false },
      ],
      customTitle: '自定义（支持最多3个键位的组合键）',
      f: null,
      input1: '',
      input2: '',
      input3: '',
      customEnabled: false,
      AJ1: 'Ctrl+v',
      AJ2: 'Ctrl+c',
      AJ3: 'Ctrl+x',
      AJ4: 'Ctrl+a',
      AJ5: 'Ctrl+z',
      AJ6: '',
      globalClickedIndex: null,
      positions: [
      ],
      switchIcons: [],
      serialData: {},

    };

  },
  
  computed: {
    ...mapGetters(['getSerialData','getDeviceInfo']),
    dynamicText() {
      // return `${this.input1}+${this.input2}+${this.input3}`;
      return [this.input1, this.input2, this.input3].filter(Boolean).join('+');
    },
    // filteredHistoryList() {
    //   return this.historyList.filter(item => item.enabled);
    // }
  },
  created() {
    this.loadHistory();
    this.getBleKeyMap();
   
    
  },
  mounted() {
    const imageId =this.$route.params.imageId;
      if(imageId == 1 ){
        this.imgName = "页面一";
      }else if(imageId == 2 ){
        this.imgName = "页面二";
      }else if(imageId == 3 ){
        this.imgName = "页面三";
      }
      console.log( "sm ",this.imgName);
    try {
      this.serialData = this.getDeviceInfo;
      this.batteryPercentage = this.serialData.battery_level;
    } catch (error) {
      console.error('Error:', error);
      ElMessageBox.alert('当前未连接，请连接后重试!', {
        confirmButtonText: '确定',
        type: 'error',
    
      });
    }

    // this.updateHistoryListFromLocalStorage();


    this.positions = [
      { x: 100, y: 80, text: '按键一 ' + this.AJ1 },
      { x: 320, y: 80, text: '按键二 ' + this.AJ2 },
      { x: 400, y: 160, text: '按键三 ' + this.AJ3 },
      { x: 10, y: 260, text: '旋转右 ' + this.AJ4 },
      { x: 318, y: 360, text: '旋转左 ' + this.AJ5 },
    ];
    this.canvas = this.$refs.myCanvas;
    this.ctx = this.canvas.getContext('2d');
    this.img = new Image();
    this.img.src = this.imageSrc.src;
    this.img.onload = () => {
      this.renderCanvas();
      this.canvas.addEventListener('click', this.handleCanvasClick);
    };
    document.addEventListener('keydown', this.handleKeyDown);
    this.initializeSwitchIcons();
  },
  methods: {
    ...mapActions(['sendSerialData']),

    drawTextWithBorder(ctx, text, x, y, isSelected = false) {
      ctx.font = 'bold  16px Arial';
      const textWidth = ctx.measureText(text).width;
      const textHeight = 50; // 固定高度
      const borderRadius = 8;


      ctx.fillStyle = isSelected ? 'rgb(0, 245, 0)' : 'white';
      ctx.beginPath();
      ctx.roundRect(x - 5, y - textHeight - 5, textWidth + 10, textHeight + 10, borderRadius);
      ctx.fill();
      ctx.lineWidth = 0.5;
      ctx.strokeStyle = isSelected ? 'green' : 'black';
      ctx.stroke();

      ctx.fillStyle = 'black';
      ctx.fillText(text, x, y - textHeight / 2 + 10); // 文字垂直居中
    },


    handleCanvasClick(event) {
      try {
        const rect = this.canvas.getBoundingClientRect();
        const clickX = event.clientX - rect.left;
        const clickY = event.clientY - rect.top;
        const clickedIndex = this.positions.findIndex((position, index) =>
          this.isPointInsideText(position.x, position.y, index, clickX, clickY)
        );
        this.globalClickedIndex = clickedIndex; // 更新全局变量
        if (clickedIndex !== -1) {
          this.positions.forEach((position, index) => {
            if (index === clickedIndex) {
              this.drawTextWithBorder(this.ctx, position.text, position.x, position.y, true);
            } else {
              this.drawTextWithBorder(this.ctx, position.text, position.x, position.y, false);
            }
          });
        } else {
          this.drawTextWithBorder(this.ctx, this.positions[this.selectedTextIndex].text, this.positions[this.selectedTextIndex].x, this.positions[this.selectedTextIndex].y, false);
          this.selectedTextIndex = null;
        }
      } catch (error) {
        console.log(error);
        ElMessageBox.alert('当前未连接或未选择按键!，请重试！', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },

    isPointInsideText(x, y, index, clickX, clickY) {
      const ctx = this.ctx;
      const positions = this.positions;
      return (
        clickX >= x - 5 && clickX <= x + 10 + ctx.measureText(positions[index].text).width &&
        clickY >= y - 80 - 5 && clickY <= y - 5
      );
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height); // 使用 this.ctx 和 this.canvas
      this.ctx.drawImage(this.img, 0, 0, this.img.width, this.img.height); // 使用 this.ctx 和 this.img
    },
    renderCanvas() {
      this.ctx.drawImage(this.img, 0, 0, this.img.width, this.img.height); // 使用 this.ctx 和 this.img
      this.positions.forEach((position) => { // 使用 this.positions
        this.drawTextWithBorder(this.ctx, position.text, position.x, position.y, false); // 使用 this.ctx
      });
    },



    initializeSwitchIcons() {
      this.switchIcons = document.querySelectorAll('.ule li:not(:first-child) .switch-icon, .ulea li:not(:first-child) .switch-icon');
      // this.switchIcons = this.$el.querySelectorAll('.ule li:not(:first-child) .switch-icon');
      this.switchIcons.forEach(icon => {
        icon.addEventListener('click', () => this.handleSwitchIconClick(icon));
      });
    },
    handleSwitchIconClick(icon) {
      const listItem = icon.parentElement;
      const textContent = listItem.textContent.replace(/^\s*\[.+\]\s*/, '').trim();
      console.log("获取到的文字:", textContent);

      if (this.globalClickedIndex != null) {
        switch (this.globalClickedIndex) {
          case 0:
            this.AJ1 = textContent;
            this.updatePositionText(0, '按键一 ' + this.AJ1);
            localStorage.setItem('1', this.AJ1);
            break;
          case 1:
            this.AJ2 = textContent;
            this.updatePositionText(1, '按键二 ' + this.AJ2);
            localStorage.setItem('2', this.AJ2);
            break;
          case 2:
            this.AJ3 = textContent;
            this.updatePositionText(2, '按键三 ' + this.AJ3);
            localStorage.setItem('3', this.AJ3);
            break;
          case 3:
            this.AJ4 = textContent;
            this.updatePositionText(3, '旋转左 ' + this.AJ4);
            localStorage.setItem('4', this.AJ4);
            break;
          case 4:
            this.AJ5 = textContent;

            this.updatePositionText(4, '旋转右 ' + this.AJ5);
            localStorage.setItem('5', this.AJ5);
            break;
          case 5:
            this.AJ6 = textContent;
            this.updatePositionText(5, '按键六 ' + this.AJ6);
            localStorage.setItem('6', this.AJ6);
            break;
        }

        this.clearCanvas();
        this.renderCanvas();

        const newArr = this.getVirtualKeyConfig(textContent);

        const imageId =this.$route.params.imageId;

          if(imageId == 1){
              this.f = 'f' + (this.globalClickedIndex + 1);
          }else if(imageId == 2){
              this.f = 'f' + (this.globalClickedIndex + 6);
          }else if(imageId == 3){
              this.f = 'f' + (this.globalClickedIndex + 11);
          }
        // const serialData = this.getSerialData;
        try {
          const setBluetooth = {
            "id": this.serialData.id,
            "method": "set_ble_key_map",
            [this.f]: newArr,
            "fm1": 0
          }
          this.sendSerialData(setBluetooth);
          // console.log("发送数据", setBluetooth);
        } catch (error) {
          console.log(error);
          ElMessageBox.alert('未打开串口，请确定打开后操作!', {
            confirmButtonText: '确定',
            type: 'error',
          });
        }
        this.toggleSwitchIcon(icon);
      } else {
        alert("请选择一个按键");
      }
    },
    updatePositionText(index, newText) {
      this.positions[index].text = newText;
    },
    toggleSwitchIcon(icon) {
      icon.classList.toggle('fa-toggle-on');
      icon.classList.toggle('fa-toggle-off');
    },

    getVirtualKeyConfig(textContent) {
      const virtualKeyConfig = {
        'ctrl': '0x80',
        'shift': '0x81',
        'alt': '0x82',
        'gui': '0x83',
        'arrow': '0xD8',
        'tba': '0xB3',
        'return': '0xB0',
        'esc': '0xB1',
        'insert': '0xD1',
        'prtsc': '0xCE',
        'delete': '0xD4',
        'page_up': '0xD3',
        'page_down': '0xD6',
        'home': '0xD2',
        'end': '0xD5',
        'caps_lock': '0xC1',
        'f1': '0xc2',
        'f2': '0xC3',
        'f3': '0xC4',
        'f4': '0xC5',
        'f5': '0xC6',
        'f6': '0xC7',
        'f7': '0xC8',
        'f8': '0xC9',
        'f10': '0xCB',
        'f11': '0xCC',
        'f12': '0xCD',
        'f13': '0xF0',
        'f14': '0xF1',
        'f15': '0xF2',
        'f16': '0xF3',
        'f17': '0xF4',
        'f18': '0xF5',
        'f19': '0xF6',
        'f20': '0xF7',
        'f21': '0xF8',
        'f22': '0xF9',
        'f23': '0xFA',
        'f24': '0xFB',
        // 'volume_up': '0xB0', // 音量加
        // 'volume_down': '0xAF', // 音量减
        // 'arrow_left': '0x25', // 左方向键
        // 'arrow_right': '0x27', // 右方向键
        // 'arrow_up': '0x26', // 上方向键
        // 'arrow_down': '0x28' // 下方向键
      };
      // 动态创建一个包含十进制值的映射表
      const virtualKeyMap = {};
      for (let key in virtualKeyConfig) {
        virtualKeyMap[key] = parseInt(virtualKeyConfig[key], 16);
      }
      let parts = textContent.split('+');
      let setf = [];

      parts.forEach(part => {
        let lowerCasePart = part.replace(/[A-Z]/g, letter => letter.toLowerCase());
        let virtualKeys = [];
        let lastCharCode = null;

        // 检查是否包含虚拟按键
        for (let key in virtualKeyMap) {
          if (lowerCasePart.includes(key)) {
            let decimalValue = virtualKeyMap[key];
            virtualKeys.push(decimalValue);
          }
        }
        // 去除所有虚拟按键，获取最后跟随的字符
        let keysToReplace = Object.keys(virtualKeyMap).join('|');
        let cleanedPart = lowerCasePart.replace(new RegExp(`(${keysToReplace})`, 'g'), '');

        if (cleanedPart.length > 0) {
          lastCharCode = cleanedPart.charCodeAt(cleanedPart.length - 1);
        }

        if (virtualKeys.length > 0 && lastCharCode !== null) {
          // 如果有虚拟按键并且跟随有字符
          setf.push(`${virtualKeys.join(', ')}, ${lastCharCode}`);
        } else if (virtualKeys.length > 0) {
          // 只有虚拟按键，没有跟随的字符
          setf.push(`${virtualKeys.join(', ')}`);
        } else if (lastCharCode !== null) {
          // 只有字符，没有虚拟按键
          setf.push(`${lastCharCode}`);
        }
      });

      //获取数字
      let newArr = setf.map(str => parseInt(str, 10));
      return newArr;
    },

    handleKeydown(input, event) {
      event.preventDefault();
      const keyValue = event.key;

      // 只允许一个字符值
      if (/^[a-zA-Z0-9]$/.test(keyValue)) { // 字母和数字键
        this[input] = keyValue;
      } else if (keyValue === 'Enter') { // 回车键
        this[input] = 'Enter';
      } else if (['Control', 'Alt', 'Shift'].includes(keyValue)) { // 控制键
        this[input] = keyValue;
      } else if (/^F\d+$/.test(keyValue)) { // 功能键 F1-F12
        this[input] = keyValue;
      } else if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(keyValue)) { // 方向键
        this[input] = keyValue;
      } else if (/^Media/.test(keyValue)) { // 多媒体键
        this[input] = keyValue;
      } else {
        this[input] = keyValue; // 其他特殊键
      }
    },
    updateRecommendedList() {
      if (this.customEnabled) {
        const newItem = {
          keyCombination: this.dynamicText,
          enabled: false, // 默认关闭
        };

        // 如果推荐列表中没有相同的组合键，则添加
        if (!this.recommendedList.find(item => item.keyCombination === newItem.keyCombination)) {
          this.recommendedList.push(newItem);
        }
      }
      this.$nextTick(() => {
        this.initializeSwitchIcons();
      });
    },
    
    loadHistory() {
      const history = [];
      const seenCombinations = new Set();
      for (let i = 1; i <= 5; i++) {
        const itemString = localStorage.getItem(i);
        if (itemString && !seenCombinations.has(itemString)) {
          seenCombinations.add(itemString);
          const item = { keyCombination: itemString, enabled: false }; // 默认开关为关闭
          history.push(item);
        }
      }
      this.historyList = history;
    },
    //获取设备的虚拟键，重新赋值
  
    getBleKeyMap(){
    //   console.log("加载键值对");
    //   const User = this.getDeviceInfo;
    //   const getMap= {
    //       "id":User.id,
    //       "method":"get_ble_key_map"
    //   };
    // const map= this.sendSerialData(getMap);
    //   console.log(map);
    //   this.AJ1 = this.decimalToKeyCombination(map.f1);
    //   this.AJ2 = this.decimalToKeyCombination(map.f2);
    //   this.AJ3 = this.decimalToKeyCombination(map.f3);
    //   this.AJ4 = this.decimalToKeyCombination(map.f4);
    //   this.AJ5 = this.decimalToKeyCombination(map.f5);
    },
    decimalToKeyCombination(decimalArray) {
    const reverseKeyMap = {};
    for (let key in this.virtualKeyMap) {
      reverseKeyMap[this.virtualKeyMap[key]] = key;
    }
    return decimalArray.map(dec => reverseKeyMap[dec] || String.fromCharCode(dec)).join(' + ');
  }
    // updateHistoryListFromLocalStorage() {
    //   for (let i = 1; i <= 5; i++) {
    //     const storedValue = localStorage.getItem(i.toString());
    //     if (storedValue) {
    //       this.historyList.push({
    //         enabled: true,
    //         keyCombination: storedValue
    //       });
    //     }
    //   }
    // },
    // toggleItemEnabled(item) {
    //   item.enabled = !item.enabled;
    //   // const index = this.historyList.indexOf(item);
    //   // if (item.enabled) {
    //   //   localStorage.setItem((index + 1).toString(), item.keyCombination);
    //   // } else {
    //   //   localStorage.removeItem((index + 1).toString());
    //   // }
    // }
  },

};


</script>
<style>
.container1 * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}


/* .container1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
} */

.left-half,
.right-half {
  width: 50%;
  /* 每个部分占50%，即各占一半 */
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  padding: 20px;
}

.left-half {
  padding: 0;
  background-color: #f4f4f4;
  /* 灰色背景 */
  color: #000;
  /* 文字颜色，确保在灰色背景下可读 */
  flex-direction: column
}

.jiantou {
  display: inline-block;
  padding: 0 0 20px 20px;
}

.right-half {
  padding: 0;
  background-color: hsl(0, 0%, 100%);
  /* 白色背景 */
  width: 50%;

}

.header2 {
  width: 100%;
  height: 50px;
  text-align: left;
  /* padding-bottom: 20px; */
  border-bottom: 1px solid #eee;
  /* 可选，增加分割线 */
  padding-left: 28px;
  padding-top: 10px;
}

.white-content2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.white-content2 .header2 {
  background-color: #ccc;
}

.img-div2 {
  padding-left: 120px;
  padding-top: 120px;
}

.list-container {
  display: flex;
  /* 启用Flex布局 */
  justify-content: space-around;
  padding-top: 100px;

}

.ule,
.ulea {
  /* 可以根据需要调整列表样式，比如列表项间距等 */
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  /* 保持列表项垂直排列 */
}

/* 保持开关图标和其他样式的定义 */
.switch-icon {
  font-size: 24px;
  /* 增大这个值来让图标变大 */
  margin-right: 15px;
  /* 示例间距，可根据需要调整 */
}

.ule li,
.ulea li {
  margin-bottom: 15px;
  /* 下方间距 */
  margin-right: 200px;
  /* 新增：右侧间距 */
}

.ule li:last-child,
.ulea li:last-child {
  margin-right: 0;
  /* 清除最后一项的右侧间距 */
}

.nav-items2 {
  width: 10%;
  display: flex;
  /* 添加白色背景 */
  background-color: white;
  /* 设置边框颜色和宽度，这里以1px黑色边框为例 */
  border: 1px solid black;
  /* 调整四个角的圆角程度，数字越大角越圆滑 */
  border-radius: 10px;
  /* 您可以根据需要调整这个值 */
  /* 如果需要内边距可以添加如下 */
  padding: 10px;
  margin-left: 330px;
  margin-top: -150px;
}

.input-group {
  display: flex;
  align-items: center;
  /* 使文本垂直居中对齐 */
  padding-left: 28px;
  padding-top: 48px;
}

.input-group input[type="text"] {
  width: 150px;
  height: 50px;
  margin-right: 35px;
}

/* 自定义勾选框样式 */
#customCheckbox {
  /* 隐藏原始勾选框 */
  position: absolute;
  opacity: 0;
}

/* 为勾选框的label添加样式 */
#customCheckbox+label {
  position: relative;
  cursor: pointer;
  padding-left: 30px;
  /* 为勾选框前的空白区域留空间 */
}

/* 自定义勾选标记 */
#customCheckbox+label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  background-color: white;
}

/* 当勾选框被选中时的样式 */
#customCheckbox:checked+label::before {
  background-color: #2196F3;
  border-color: #2196F3;
}

/* 可选：添加一个勾号图标 */
#customCheckbox:checked+label::after {
  content: "\2713";
  /* 使用Unicode字符作为勾号 */
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  color: white;
  font-size: 14px;
  line-height: 0;
}

.switch-list {
  padding-left: 28px;
  padding-top: 18px;
}

/* 默认关闭状态颜色 */
.switch-icon.fa-toggle-off {
  color: gray;
  /* 或您希望的关闭状态颜色 */
}

/* 打开状态颜色 */
.switch-icon.fa-toggle-on {
  color: rgb(6, 199, 6);
  /* 开启状态时图标变为绿色 */
}

.text-box {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
}

.text-box:hover {
  border-color: red;
}

.text-box.selected {
  border-color: red;
}

.switch-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.hidden2 {
  display: none;
}

.txtClass {
  display: none;
  margin-top: -24px;
  margin-left: 37px;
  font-size: 19px;
}
.img-text{
    margin-left: 210px ;
    padding: 5px;
    font-family:  Georgia, serif;
    font-weight: bold;
    font-size:  20px;
}


</style>